import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"

import MetaWp from "../components/meta-wp"
import Layout from "../components/layout"
import Link from "../components/link"
import Button from "../components/button"
import looseBaloonSvg from "../images/illustrations/loose-baloon.svg"

const animIllustrationSlideIn = keyframes`
  0% { top: 100%; }
  100% { top: 0; }
`

const animIllustrationLevitate = keyframes`
  50% { transform: translateY(${rem(20)}); }
`

const Container = styled.article`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: ${(props) => props.theme.colorRedLight};
`

const Inner = styled.div`
  width: 100%;
  max-width: ${rem(768)};
  margin: 10vh auto 0;
  padding: ${rem(0)} ${rem(50)};
  display: flex;
  justify-content: space-between;

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(30)};
    padding: 0 ${rem(30)};
    flex-direction: column;
    align-items: center;
  }

  @media ${(props) => props.theme.xxsmallDown} {
    padding: 0 ${rem(20)};
  }
`

const Message = styled.div`
  width: 55%;
  margin: ${rem(40)} 0 ${rem(100)};

  @media ${(props) => props.theme.smallDown} {
    width: 100%;
    max-width: ${rem(320)};
    margin: 0 0 ${rem(50)};
  }

  h1 {
    margin-bottom: ${rem(10)};

    sup {
      font-size: 0.4em;
      opacity: 0.6;
    }
  }

  p {
    margin-bottom: ${rem(10)};
  }
`

const Illustration = styled.div`
  width: 30%;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.smallDown} {
    width: 100%;
    max-width: ${rem(150)};
    height: 100vh;
    margin-left: 0;
  }

  img {
    width: 100%;
    display: block;
    position: relative;
    z-index: 2;
  }

  div {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    will-change: top;
    animation: ${animIllustrationSlideIn} 2s 1s forwards
        ${(props) => props.theme.easingOutCubic},
      ${animIllustrationLevitate} 4s 3s infinite
        ${(props) => props.theme.easingInOutCubic};

    &::after {
      content: "";
      width: ${rem(5)};
      height: ${rem(4000)};
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${(props) => props.theme.colorPurpleDarkest};
    }
  }
`

const Logo = styled.div`
  margin-bottom: ${rem(40)};

  a {
    display: block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  svg {
    width: ${rem(90)};
    height: ${rem(45)};
    display: block;
    object-fit: contain;

    @media ${(props) => props.theme.xlargeDown} {
      width: ${rem(60)};
      height: ${rem(30)};
    }
  }
`

const Page404 = ({ data }) => {
  const {
    site: { siteMetadata },
    wordpressAcfOptions: { globalMeta },
  } = data

  return (
    <Layout hideHeader={true} hideFooter={true}>
      <MetaWp
        title="Hoppsan! 404"
        description="Innehållet du letar efter har flyttats eller tagits bort"
        global={globalMeta}
        defaults={siteMetadata}
      />

      <Container>
        <Inner>
          <Message>
            <Logo>
              <Link to="/" title={process.env.GATSBY_SITE_NAME}>
                <img
                  src={`/images/logos/${process.env.GATSBY_SITE_KEY}.svg`}
                  alt={process.env.GATSBY_SITE_NAME}
                />
              </Link>
            </Logo>

            <h1 className="styled-h1">
              Hoppsan! <sup>404</sup>
            </h1>

            <p className="styled-p styled">
              Innehållet du letar efter har flyttats eller tagits bort.
            </p>

            <Button to="/" opts="soft">
              Gå till hemsidan
            </Button>
          </Message>

          <Illustration>
            <div>
              <img src={looseBaloonSvg} alt="Hoppsan! 404" />
            </div>
          </Illustration>
        </Inner>
      </Container>
    </Layout>
  )
}

Page404.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page404

export const pageQuery = graphql`
  query {
    site {
      ...SiteMetaData
    }

    wordpressAcfOptions {
      ...GlobalMetaOptions
    }
  }
`
